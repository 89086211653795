import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { graphql, Link } from "gatsby";
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipeDynamicCaption from 'photoswipe-dynamic-caption-plugin';
import loadable from "@loadable/component";
import Layout from "../layouts";

const JWPlayer = loadable(() => import("@jwplayer/jwplayer-react"));

export default ({ data }) => {
  const [videoIndex, setVideoIndex] = useState(0);

  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: '#gallery',
      children: 'a',
      pswpModule: () => import('photoswipe'),
      paddingFn: (viewportSize) => {
        return {
          top: 30,
          bottom: 30,
          left: 70,
          right: 70,
        }
      }
    });
    let captionPlugin = new PhotoSwipeDynamicCaption(lightbox, {
      type: 'auto',
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    }
  }, []);

  const entry = data.allSitePage.edges[0].node.pageContext;

  return (
    <Layout title={entry.name}>
      <div className="px-4 py-14 lg:px-20 lg:py-20">
        <Link
          to="/#interviews"
          class="text-lg items-center focus:outline-none border-none inline-flex mb-3"
        >
          <svg
            class="w-6 h-6 inline-block"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            ></path>
          </svg>
          <span class="ml-2">返回</span>
        </Link>
        <h1 class="section-title text-center text-xl lg:text-4xl font-medium lg:mb-8 mb-5">
          {entry.name}
        </h1>
        <div className="lg:mb-8 mb-5">
          {entry.videos && entry.videos.length === 1 ? (
            <JWPlayer
              library="https://cdn.jwplayer.com/libraries/j4rTOaLQ.js"
              file={entry.videos[0].stream}
              image={entry.videos[0].poster}
            />
          ) : (
            <div className="">
              <ul class="nav-select font-medium text-center text-gray-500 rounded-lg shadow lg:flex mb-8">
                {entry.videos.map((video, i) => (
                  <li class="w-full">
                    <button
                      className={clsx(
                        "inline-block lg:p-4 p-2 w-full focus:outline-none",
                        videoIndex === i ? "current" : ""
                      )}
                      onClick={() => setVideoIndex(i)}
                    >
                      {video.title}
                    </button>
                  </li>
                ))}
              </ul>
              {entry.videos.map((video, i) => {
                if (videoIndex === i) {
                  return (
                    <JWPlayer
                      library="https://cdn.jwplayer.com/libraries/j4rTOaLQ.js"
                      file={entry.videos[i].stream}
                      image={entry.videos[i].poster}
                    />
                  );
                }
                return null;
              })}
            </div>
          )}
        </div>
        {entry.description && (
          <div class="lg:mb-10 mb-5">
            <h3 class="lg:text-xl font-medium text-green-s mb-2">被訪者簡介</h3>
            <div>
              <ul class="list-disc ml-5">
                {entry.description.map((item, i) => (
                  <li key={i} class="lg:text-lg mb-2">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {entry.photos && (
          <div class="mb-10">
            <h3 class="lg:text-xl font-medium text-green-s mb-2">相片集</h3>
            <div className="pswp-gallery" id="gallery">
              <div className="grid grid-cols-3 lg:grid-cols-6 gap-2 lg:gap-4 mb-5">
                {entry.photos.map((item, i) => (
                  <div
                    key={i}
                    className="flex items-center justify-center cursor-pointer"
                  >
                    <a
                      href={item.src}
                      data-pswp-width={item.width}
                      data-pswp-height={item.height}
                      target="_blank"
                    >
                      <img src={item.tn} alt="" className="lg:max-h-48 max-h-32" />
                      <div className="pswp-caption-content">
                        {item.description.map((desc, j) => (
                          <p className="" key={j}>
                            {desc}
                          </p>
                        ))}
                        {item.copyright &&
                          item.copyright.map((cr, j) => (
                            <p className="mt-4" key={j}>
                              {cr}
                            </p>
                          ))}
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          pageContext
        }
      }
    }
  }
`;